import { components } from '@/generated/internal-api/openapi-types';
import { AnalysisConfig } from './Analysis.model';
import type { ID } from './Common.model';
import type { PresetCalculation } from './PresetCalculation.model';

export type PresetApiId = components['schemas']['PresetApiId.schema'];

export interface Preset {
  analysis_config?: { version: number; config: Array<AnalysisConfig> };
  id: ID;
  calculations: Array<PresetCalculation>;
  title: string;
  dependency_tree: Record<string, Array<string>>;
  graph_settings?: GraphSetting;
  exclude_before_tracking_date?: boolean;
}

export enum PresetReservedMeasurement {
  animal_food_intake,
  animal_fluid_intake,
}

export type PresetV1 = components['schemas']['PresetV1.schema'];
export type PresetSummaryV1 = components['schemas']['PresetSummaryV1.schema'];
export type PresetMeasurementCreateV1 = components['schemas']['PresetMeasurementCreateV1.schema'];
export type PresetCreateOrUpdateV1 = components['schemas']['PresetCreateOrUpdateV1.schema'];
export type PresetMeasurementInputV1 = components['schemas']['PresetMeasurementInputV1.schema'];
export type GraphSetting = 'tracking_date' | 'disease_induction';
export type ActiveVariablesResponseV1 = components['schemas']['ActiveVariablesResponseV1.schema'];
