import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import { TimezoneSelect } from '@/components/TimezoneSelect/TimezoneSelect.tsx';
import Button from '@/components/UI/Button';
import { _isEmptyString, _isNil, _notNil } from '@/littledash';
import type { TimeZoneUser } from '@/model/User.model';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { ErrorMessage } from '@hookform/error-message';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form@latest';
import { useDispatch } from 'react-redux';

interface CompleteAccountModalProps {
  closeModal: () => void;
  timezones: Array<string>;
  user: TimeZoneUser;
}

interface FormDataProps {
  name: string;
  timezone: string;
}

const CompleteAccountModal: FC<CompleteAccountModalProps> = ({ closeModal, user, timezones }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<unknown>();
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const timezoneSet = useMemo(() => new Set(timezones), [timezones]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    trigger,
  } = useForm<FormDataProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      name: user?.name ?? '',
      timezone: user?.timezone === 'UTC' ? DateUtils.browserTimezone() : user.timezone,
    },
  });
  const onSubmit = async (formData: FormDataProps) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Http.put(apiRoutes('user.update'), { name: formData.name.trim(), timezone: formData.timezone });
      dispatch({
        type: 'USER_SET_CURRENT_USER',
        currentUser: data,
      });
      if (isMounted()) {
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      if (isMounted()) {
        setApiError(error);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    trigger();
  }, [trigger]);
  const nameFieldHidden = (user?.name ?? '').trim().length !== 0 && _isNil(errors.name);
  const timeZoneFieldHidden =
    user?.timezone !== 'UTC' && (timezoneSet?.has(user?.timezone) ?? false) && _isNil(errors.timezone);
  return (
    <div className="center mw6 ui-card">
      <div className="pa3 bg-white bb b--moon-gray">
        <h2 className="fw5 lh-title f5 mb2">Select timezone</h2>
        {_notNil(apiError) && (
          <ApiErrorBanner
            title="There was an error with your request"
            text="An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support."
            error={apiError}
            errorType={ApiErrorType.SUBMIT}
          />
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pa3">
          <div className="w-100">
            <div hidden={nameFieldHidden}>
              <label className="mb2 mt2">Full Name</label>
              <input
                className={_notNil(errors.name) ? 'input__error' : ''}
                style={{ marginBottom: 0 }}
                type="text"
                disabled={loading}
                {...register('name', {
                  required: 'Name is required',
                  validate: {
                    notBlank: (value?: string) => ((value ?? '').trim().length === 0 ? 'Name cannot be blank' : true),
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
            <div hidden={timeZoneFieldHidden}>
              <label className="mb2 mt2">Timezone</label>
              <Controller
                name="timezone"
                control={control}
                rules={{
                  validate: (value: string) => {
                    if (_isEmptyString(value ?? '')) {
                      return 'Timezone is required';
                    } else if (!timezoneSet.has(value)) {
                      return `Your timezone ${value} is not available. If an appropriate alternative cannot be selected, please contact support`;
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <TimezoneSelect
                    timezones={timezones}
                    value={field.value}
                    disabled={isSubmitting}
                    invalid={fieldState.invalid}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="timezone"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
          </div>
        </div>
        <div className="pa3 mt3 bt b--moon-gray bg-white">
          <Button submit className="mr2" disabled={loading || !isValid}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CompleteAccountModal;
