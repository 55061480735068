import Loading from '@/components/Loading';
import {
  filterTreatmentsByClassification,
  tempEntityArrayToMap,
} from '@/components/Studies/Create/Steps/GroupsAndTreatments/GroupsAndTreatment.utils';
import { TreatmentsTable } from '@/components/Studies/Create/Steps/GroupsAndTreatments/TreatmentsTable';
import { addAdditionalStudyCreateProperties, TreatmentFromForm } from '@/components/Studies/Treatments/Treatment.utils';
import Button from '@/components/UI/Button';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import { _isEmpty, _isNotEmpty, _notNil, uuid } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { DraftFormData } from '@/model/Draft.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { ClassificationType, Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { modalAction } from '@/utils/modal';
import { Dispatch, FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

interface TreatmentBulkActionsProps {
  selectedRows: Record<number, boolean>;
  handleDuplicate: () => void;
  handleDelete: () => void;
}

const TreatmentBulkActions: FC<TreatmentBulkActionsProps> = ({ selectedRows, handleDuplicate, handleDelete }) => {
  const selectedRowCount = useMemo(() => Object.keys(selectedRows ?? {}).length, [selectedRows]);
  const hasSelectedRows = selectedRowCount > 0;

  const actions = [
    {
      name: 'Duplicate',
      action: handleDuplicate,
    },
    {
      name: 'Delete',
      key: 'delete',
      action: handleDelete,
    },
  ];
  return (
    <div className="w-100 flex items-center mt3">
      {hasSelectedRows && (
        <div
          className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
          style={{
            whiteSpace: 'nowrap',
            lineHeight: '2.4rem',
          }}
        >
          {selectedRowCount} selected
        </div>
      )}
      <SelectDropDown
        title="Bulk actions"
        className={`plain f6 ${hasSelectedRows ? 'br--right' : ''}`}
        alignMenu="right"
        disabled={!hasSelectedRows}
      >
        <ActionList actions={actions} />
      </SelectDropDown>
    </div>
  );
};

interface TreatmentCreationProps {
  study: DraftFormData;
  classificationType: ClassificationType;
  dispatch: Dispatch<{ type: string; data: Partial<DraftFormData> }>;
}

export const TreatmentCreation: FC<TreatmentCreationProps> = ({ study, classificationType, dispatch }) => {
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});

  const groups = study?.groups;
  const globalDispatch = useDispatch();
  const { openModal, closeModal } = modalAction(globalDispatch);

  const { collection: metadata, collectionLoading: treatmentMetasLoading } = useFetchCollection<MetadataField>({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: classificationType === 'disease_induction' ? 'disease_meta' : 'treatment_meta',
    },
  });

  const { treatments } = useMemo(() => {
    const filteredTreatments = filterTreatmentsByClassification(study?.treatments ?? [], classificationType);
    const groupsMap = tempEntityArrayToMap<TreatmentGroup>(study?.groups);
    const treatmentGroupsMap = (study?.groups_treatments ?? []).reduce((acc, { group: groupId, treatments }) => {
      const group = groupsMap.get(groupId.toString());
      if (_notNil(group)) {
        treatments.forEach((treatmentId) => {
          acc.set(treatmentId, [...(acc.get(treatmentId) ?? []), group]);
        });
      }
      return acc;
    }, new Map());

    return {
      treatments: filteredTreatments.map((treatment) => ({
        ...treatment,
        study_groups: treatmentGroupsMap.get(treatment.temp_id) ?? [],
      })),
    };
  }, [study, classificationType]);

  const handleAddTreatment = async (treatmentData: Treatment) => {
    if (_notNil(treatmentData.temp_id)) {
      // Filter out any existing treatment with the same ID, then add the updated one
      const filteredTreatments = (study.treatments ?? []).filter((t) => t.temp_id !== treatmentData.temp_id);

      dispatch({
        type: 'updateStudy',
        data: { ...study, treatments: [...filteredTreatments, treatmentData] },
      });
      closeModal();
    }
  };

  /**
   *
   * @param treatmentIdsToDelete {Set<string>}
   */
  const handleDeleteTreatments = (treatmentIdsToDelete: Set<ID>) => {
    setSelectedRows({});

    dispatch({
      type: 'updateStudy',
      data: {
        treatments: (study?.treatments ?? []).filter((t) => t.temp_id && !treatmentIdsToDelete.has(t.temp_id)),
        groups_treatments: (study?.groups_treatments ?? []).map(({ group, treatments }) => ({
          group,
          treatments: _isEmpty(treatments)
            ? []
            : treatments.filter((treatmentId) => !treatmentIdsToDelete.has(treatmentId)),
        })),
      },
    });
  };

  const handleTreatmentDuplicate = () => {
    const duplicatedTreatments = Object.entries(selectedRows).reduce<Array<Treatment>>((acc, [row, selected]) => {
      const treatment = treatments?.[Number(row)];
      if (selected && _notNil(treatment)) {
        acc.push({
          ...treatment,
          temp_id: uuid(),
          _duplicate: true,
        });
      }
      return acc;
    }, []);

    setSelectedRows({});
    dispatch({
      type: 'updateStudy',
      data: {
        treatments: [...(study?.treatments ?? []), ...duplicatedTreatments],
      },
    });
  };

  const handleDeleteTreatmentClick = () => {
    const treatmentIdsToDelete = Object.entries(selectedRows).reduce((acc, [row, selected]) => {
      const treatmentId = treatments?.[Number(row)]?.temp_id;
      if (selected && _notNil(treatmentId)) {
        acc.add(treatmentId);
      }
      return acc;
    }, new Set<ID>());

    handleDeleteTreatments(treatmentIdsToDelete);
  };

  const onSubmit = async (formItems: TreatmentFromForm) => {
    const updatedTreatment = addAdditionalStudyCreateProperties(formItems, metadata);
    const newTreatment = { ...updatedTreatment };
    await handleAddTreatment(newTreatment);
  };

  const handleUpdateTreatments = (_: unknown, treatment?: Treatment) => {
    if (_notNil(treatment)) {
      delete treatment.study_groups;
    }
    openModal('ADD_TREATMENTS_FORM', {
      treatment: _notNil(treatment) ? treatment : null,
      metadata,
      onSubmit,
      onCancel: closeModal,
      classificationType: _notNil(treatment?.treatment_classification)
        ? treatment.treatment_classification
        : classificationType,
    });
  };

  return treatmentMetasLoading ? (
    <div className="ui-card mv4 flex items-end flex-column">
      <div className="w-100 h-100 pa4">
        <Loading txt="Loading treatments..." />
      </div>
    </div>
  ) : (
    <div className="ui-card mv4 flex items-end flex-column">
      {_isNotEmpty(treatments) && (
        <div className="w-100 flex justify-between items-center">
          <p className="ph3 lh-copy f6">
            <TreatmentBulkActions
              selectedRows={selectedRows}
              handleDuplicate={handleTreatmentDuplicate}
              handleDelete={handleDeleteTreatmentClick}
            />
          </p>
          <Button className="ma3" onClick={handleUpdateTreatments}>
            Add New
          </Button>
        </div>
      )}
      <TreatmentsTable
        treatments={treatments}
        metadata={metadata}
        groups={groups}
        handleAddOrUpdateTreatmentsClick={handleUpdateTreatments}
        selectedRows={selectedRows}
        selectedRowsChange={setSelectedRows}
        classificationType={classificationType}
      />
    </div>
  );
};
