import { FC } from 'react';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import Header from '@/components/UI/Header';
import { DataTablesList } from '@/components/Studies/DataTables/List/DataTablesList.tsx';
import { DataTableTemplatesList } from '@/components/Studies/DataTables/List/DataTableTemplatesList.tsx';

const DataTables: FC = () => {
  return (
    <>
      <div className="ph4 pt3">
        <Header mainHeaderText="Data Tables" />
        <Tabs>
          <div className="mb4 bb b--moon-gray pt3">
            <Tab className="f4" testId="data-tables-active-tab">
              Active
            </Tab>
            <Tab className="f4" testId="data-tables-templates-tab">
              Templates
            </Tab>
          </div>
          <div>
            <Panel>
              <DataTablesList />
            </Panel>
            <Panel>
              <DataTableTemplatesList />
            </Panel>
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default DataTables;
