import { DataTableRowSearchMenuItem } from '@/components/UI/DataTable/toolbar/Menus/Search/DataTableRowSearchMenuItem';
import { DataTableWorkflowMenuItem } from '@/components/UI/DataTable/toolbar/Menus/WorkflowSetup/DataTableWorkflowMenuItem';
import { Dropdown } from '@/components/UI/Dropdown';
import Spinner from '@/components/UI/Spinner';
import Tooltip from '@/components/UI/Tooltip';
import { errorToast, successToast } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalApiId } from '@/model/Animal.model';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  RiAlertFill,
  RiArrowDropDownFill,
  RiArrowGoBackFill,
  RiArrowGoForwardFill,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiSaveFill,
} from 'react-icons/ri';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import { useModalAction } from '@/utils/modal';
import type {
  DataTableColumnAddRequest,
  DataTableColumnApiId,
  DataTableErrors,
  DataTableSavingEvent,
  DataTableService,
  DataTableUndoRedoStateChangeEvent,
} from '../DataTable.model';
import { DataTableCellStatusType, DataTableEvent } from '../DataTable.model';
import styles from './DataTableToolbar.module.scss';
import { AddColumn } from './Menus/AddColumn/AddColumn';
import ShowRowHeaders from './Menus/ShowRowHeaders/ShowRowHeaders';

interface DataTableToolbarProps {
  dataTableService: DataTableService;
  errors: DataTableErrors;
}

interface ToolbarItemProps {
  children: ReactNode;
  onClick?: () => void;
  tooltipText?: string | null;
  disabled?: boolean;
}

interface ToolbarGroupProps {
  children: ReactNode;
}

export const DataTableToolbar: FC<DataTableToolbarProps> = ({ dataTableService, errors }) => {
  const { openModal } = useModalAction();
  const readonly = useMemo(() => dataTableService.readonly, [dataTableService]);
  const [undoDisabled, setUndoDisabled] = useState(!dataTableService.undoEnabled());
  const [redoDisabled, setRedoDisabled] = useState(!dataTableService.redoEnabled());
  const [tableSaving, setTableSaving] = useState(false);

  const filteredErrors = useMemo(
    () =>
      errors.filter(
        ({ status }) =>
          !(
            status?.type === DataTableCellStatusType.ValidationWarning ||
            status?.type === DataTableCellStatusType.AsyncValidationWarning
          )
      ),
    [errors]
  );

  const scrollToFirstError = () => {
    const errorIdCoordinate = filteredErrors?.[0]?.coordinate;
    if (_notNil(errorIdCoordinate)) {
      const indexCoordinate = dataTableService.toIndexCoordinate(errorIdCoordinate);
      if (_notNil(indexCoordinate)) {
        dataTableService.selectCell(indexCoordinate, { scrollTo: true });
      }
    }
  };

  useEffect(() => {
    const undoRedoStateChangeListener = ({ detail }: DataTableUndoRedoStateChangeEvent) => {
      setUndoDisabled(!detail.undoEnabled);
      setRedoDisabled(!detail.redoEnabled);
    };
    const tableSavingListener = ({ detail: { saving } }: DataTableSavingEvent) => {
      setTableSaving(saving);
    };
    dataTableService?.subscribe(DataTableEvent.UndoRedoStateChange, undoRedoStateChangeListener);
    dataTableService?.subscribe(DataTableEvent.TableSaving, tableSavingListener);
    return () => {
      dataTableService?.unsubscribe(DataTableEvent.UndoRedoStateChange, undoRedoStateChangeListener);
      dataTableService?.unsubscribe(DataTableEvent.TableSaving, tableSavingListener);
    };
  }, [dataTableService]);

  const handleAddColumn = async (data: DataTableColumnAddRequest, ignoreType = false) => {
    if (data.type === 'formula') {
      openModal('EDIT_DATATABLE_FORMULA_COLUMN', {
        formData: { name: data.name, unit: data.unit },
        columns: dataTableService.columns,
        onSubmit: (formData: DataTableColumnAddRequest) => {
          dataTableService
            .addColumn(formData)
            .then((result) => {
              successToast('Column added');
              dataTableService.selectCell(
                { column: dataTableService.toIndexColumn(result?.at(-1)?.id as DataTableColumnApiId) ?? 0, row: 0 },
                { scrollTo: true }
              );
            })
            .catch(() => errorToast('Could not add column'));
        },
      });
    } else if (data.type === 'timestampBaseline') {
      openModal('EDIT_DATATABLE_TIMESTAMP_BASELINE_COLUMN', {
        formData: {
          name: data.name,
          from: DateInputUtils.toLocalDateTime(DateUtils.dateTimeNow(), { includeSeconds: false }),
        },
        columns: dataTableService.columns,
        onSubmit: (formData: DataTableColumnAddRequest) => {
          dataTableService
            .addColumn({
              type: 'timestampBaseline',
              name: formData.name,
              pk: {
                from: formData?.pk?.from ?? null,
                interval: formData?.pk?.interval ?? null,
                interval_time_unit: formData?.pk?.interval_time_unit ?? null,
                window: formData?.pk?.window ?? 0,
              },
            })
            .then((result) => {
              successToast('Column added');
              dataTableService.selectCell(
                { column: dataTableService.toIndexColumn(result?.at(-1)?.id as DataTableColumnApiId) ?? 0, row: 0 },
                { scrollTo: true }
              );
            })
            .catch(() => errorToast('Could not add column'));
        },
      });
    } else if (data.type === 'timestampBaselineRelative') {
      openModal('EDIT_DATATABLE_TIMESTAMP_BASELINE_RELATIVE_COLUMN', {
        formData: { name: data.name, from: DateUtils.dateTimeNow().slice(0, 19) },
        columns: dataTableService.columns,
        onSubmit: (formData: DataTableColumnAddRequest) => {
          dataTableService
            .addColumn(formData)
            .then((result) => {
              successToast('Column added');
              dataTableService.selectCell(
                { column: dataTableService.toIndexColumn(result?.at(-1)?.id as DataTableColumnApiId) ?? 0, row: 0 },
                { scrollTo: true }
              );
            })
            .catch(() => errorToast('Could not add column'));
        },
      });
    } else {
      await dataTableService
        .addColumn(data)
        .then((result) => {
          successToast('Column added');
          dataTableService.selectCell(
            { column: dataTableService.toIndexColumn(result?.at(-1)?.id as DataTableColumnApiId) ?? 0, row: 0 },
            { scrollTo: true }
          );
        })
        .catch(() => errorToast('Could not add column'));
    }
  };
  const handleAddRow = async (animals: Array<AnimalApiId>) => {
    await dataTableService
      .addRow({ animals })
      .then((result) => {
        dataTableService.selectCell(
          { column: 0, row: dataTableService.toIndexRow(result[0]?.id) ?? 0 },
          { scrollTo: true }
        );
        successToast(`Row${animals.length > 0 ? 's' : ''} added`);
      })
      .catch(() => errorToast(`Could not add row${animals.length > 0 ? 's' : ''}`));
  };
  return (
    <div className="flex justify-between items-center ph3 bb b--moon-gray">
      <div className="flex justify-apart">
        <ToolbarGroup>
          <ToolbarItem onClick={() => dataTableService.undo()} tooltipText="Undo" disabled={undoDisabled || readonly}>
            <div className="pr2 pv2 lh-solid">
              <RiArrowGoBackFill size={20} />
            </div>
          </ToolbarItem>
          <ToolbarItem
            onClick={() => dataTableService.redo().then(() => dataTableService.validate())}
            tooltipText="Redo"
            disabled={redoDisabled}
          >
            <div className="pr2 pv2 lh-solid">
              <RiArrowGoForwardFill size={20} />
            </div>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem
            tooltipText="Add rows"
            disabled={readonly}
            onClick={() =>
              openModal('ADD_DATATABLE_ROW', { handleAddAnimals: (ids: Array<AnimalApiId>) => handleAddRow(ids) })
            }
          >
            <div className="pa2 lh-solid">
              <RiInsertRowBottom size={22} />
            </div>
          </ToolbarItem>
          <ToolbarItem disabled={readonly}>
            {dataTableService.type === 'time' && _notNil(dataTableService.unit) ? (
              <Tooltip render="Add column">
                <div
                  className="pr2 pv2 lh-solid"
                  onClick={() => handleAddColumn({ type: null, name: null, unit: null })}
                >
                  <RiInsertColumnRight size={22} />
                </div>
              </Tooltip>
            ) : (
              <Dropdown
                renderMenu={({ toggleDropdownMenu }) => (
                  <AddColumn
                    columns={dataTableService.columns}
                    tableType={dataTableService.type}
                    tableUnit={dataTableService.unit ?? ''}
                    toggleDropdownMenu={toggleDropdownMenu}
                    handleAddColumn={handleAddColumn}
                  />
                )}
              >
                <Tooltip render="Add column">
                  <div className="mr2 mv2 lh-solid">
                    <RiInsertColumnRight size={22} />
                  </div>
                </Tooltip>
              </Dropdown>
            )}
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem disabled={readonly}>
            <Tooltip render="Jump to animal">
              <DataTableRowSearchMenuItem dataTableService={dataTableService} />
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem disabled={readonly}>
            <Tooltip render="Configure Navigation">
              <DataTableWorkflowMenuItem datatableService={dataTableService} disabled={readonly} />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem
            tooltipText="Save as template"
            disabled={readonly}
            onClick={() =>
              openModal('CREATE_TEMPLATE_FROM_DATA_TABLE', {
                studyApiId: dataTableService.studyApiId,
                dataTableApiId: dataTableService.tableId,
              })
            }
          >
            <div className="pa2 lh-solid">
              <RiSaveFill size={22} />
            </div>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem disabled={readonly}>
            <Dropdown
              renderMenu={({ toggleDropdownMenu }) => (
                <ShowRowHeaders dataTableService={dataTableService} toggleDropdownMenu={toggleDropdownMenu} />
              )}
            >
              <div className="flex items-center pa2">
                <span className="f6 fw5">Show</span> <RiArrowDropDownFill size={22} />
              </div>
            </Dropdown>
          </ToolbarItem>
        </ToolbarGroup>
      </div>
      {(_isNotEmpty(filteredErrors) || tableSaving) && (
        <div className="flex items-center">
          {_isNotEmpty(filteredErrors) && (
            <div className="flex items-bottom mr2">
              <RiAlertFill className="dark-red" size={18} />
              <div className="f7 lh-solid ml2">
                <p className="near-black dib mr1">
                  {filteredErrors.length} error{filteredErrors.length !== 1 ? 's' : ''} exist
                  {filteredErrors.length === 1 ? 's' : ''}.
                </p>
                <span className="blue pointer" onClick={scrollToFirstError}>
                  View {filteredErrors.length !== 1 ? 'errors' : 'error'}
                </span>
              </div>
            </div>
          )}
          {tableSaving && (
            <div className="flex items-center mh2">
              <Spinner size="small" color="" className="dib" />
              <span className="f7 near-black dib ml2 lh-title">Saving</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ToolbarGroup: FC<ToolbarGroupProps> = ({ children }) => (
  <div className={`${styles['datatable-toolbar-group']}`}>{children}</div>
);

const ToolbarItem: FC<ToolbarItemProps> = ({ children, onClick, tooltipText, disabled = false }) => (
  <div
    className={`${styles['datatable-toolbar-item']} ${disabled ? 'ui__disabled' : ''}`}
    onClick={onClick ?? undefined}
  >
    {tooltipText ? <Tooltip render={tooltipText}>{children}</Tooltip> : <>{children}</>}
  </div>
);
